import { useContext } from "react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api/api";
import {
  GetApplicantDetails,
  GetPaymentStatus,
} from "../../../api/application";
import Footer from "../../../components/Footer";
import { getCurrentUser } from "../../../components/services/auth/authService";
import AuthContext from "../../../context/AuthContext";
import { subjects } from "../../../common";
import { grading } from "../../../common";
import { ChevronDown, ChevronUp, Trash2 } from "react-feather";
import useDetails from "../../../hooks/useDetails";
import { v4 as uuidv4 } from "uuid";

const EducationDetails = () => {
  const [utmeScore, setUtmeScore] = useState("string");
  const [postUtme, setPostUtme] = useState(0);
  const [degreeChoice1, setDegreeChoice1] = useState();
  const [degreeChoice2, setDegreeChoice2] = useState();
  const [jambNo, setJambNo] = useState("string");
  const [error, setError] = useState();
  const [department, setDepartment] = useState();

  const [examRegNum, setExamRegNum] = useState("string");
  const [examName, setExamName] = useState("string");
  const [examDate, setExamDate] = useState("string");

  const [examRegNum2, setExamRegNum2] = useState("string");
  const [examName2, setExamName2] = useState("string");
  const [examDate2, setExamDate2] = useState("string");

  const [seating, setSeating] = useState("");
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();

  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");
  const [grade, setGrade] = useState("");

  const [subjectList2, setSubjectList2] = useState([]);
  const [subject2, setSubject2] = useState("");
  const [grade2, setGrade2] = useState("");

  const [prevSchool, setPrevSchool] = useState("");
  const [prevCourse, setPrevCourse] = useState("");
  const [prevGrade, setPrevGrade] = useState("");
  const [prevCGPA, setPrevCGPA] = useState("");
  const [prevMatNum, setPrevMatNum] = useState("");
  const [prevStartDate, setPrevStartDate] = useState("");
  const [prevEndDate, setPrevEndDate] = useState("");

  const [toggle, setToggle] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const user = getCurrentUser();

  const token = user?.access;

  const { data, loading: isPending, staging: stage } = useDetails();

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const getDepartments = async () => {
    try {
      const { data } = await api.get("/department/all", {
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log("Department list", data.results);
      setDepartment(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const updateBio = async (e) => {
    e.preventDefault();
    if (
      data?.entryMode === "UTME" &&
      (!degreeChoice1 ||
        !degreeChoice2 ||
        !utmeScore ||
        // !postUtme ||
        !jambNo)
    ) {
      return toast.info("Please fill all fields");
    }
    if (
      data?.entryMode === "DE" &&
      (!prevSchool ||
        !prevCourse ||
        !prevCGPA ||
        !prevGrade ||
        !prevStartDate ||
        !prevEndDate ||
        !prevMatNum)
    ) {
      return toast.info("Please provide all ND details");
    }
    try {
      setLoading(true);
      const res = await api.post(`/admissions/educational/update`, {
        degreeChoice1,
        degreeChoice2,
        utme_score: utmeScore,
        post_utme_score: postUtme,
        jambno: jambNo,
        applicant_id: user.username,
        results_1: subjectList,
        results_2: subjectList2,
        exam_name_1: examName,
        exam_reg_no_1: examRegNum,
        exam_date_1: examDate,
        exam_name_2: examName2,
        exam_reg_no_2: examRegNum2,
        exam_date_2: examDate2,
        school_attended: prevSchool,
        year_of_entry: prevStartDate,
        year_of_graduation: prevEndDate,
        matric_number: prevMatNum,
        cgpa: prevCGPA,
        class_of_degree: prevGrade,
        discipline: prevCourse,
      });
      console.log(res);
      toast.success("Education details uploaded", successProp);
      // getDetails()
      setLoading(false);
      navigate("/apply");
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Updating failed. Try again!", errorProp);
    }
  };

  const handleSubjectSelect = () => {
    if (!subject || !grade) return;
    const isPresent = subjectList.find((p) => p.subject === subject);
    if (isPresent) return toast.info(subject + " " + "already selected");
    setSubjectList([
      ...subjectList,
      {
        id: uuidv4(),
        subject: subject,
        grade: grade,
      },
    ]);
    setGrade("");
    setSubject("");
  };

  const handleSubjectSelect2 = () => {
    if (!subject2 || !grade2) return;
    const isPresent = subjectList2.find((p) => p.subject === subject2);
    if (isPresent) return toast.info(subject2 + " " + "already selected");
    setSubjectList2([
      ...subjectList2,
      {
        id: uuidv4(),
        subject: subject2,
        grade: grade2,
      },
    ]);
    setGrade2("");
    setSubject2("");
  };

  const handleCourseDelete1 = (id) => {
    let newList = subjectList.filter((item) => item.id !== id);
    setSubjectList(newList);
  };

  console.log("First sitting subjects", subjectList);

  const handleCourseDelete2 = (id) => {
    let newList = subjectList2.filter((item) => item.id !== id);
    setSubjectList2(newList);
  };

  useEffect(() => {
    setTimeout(() => {}, 10000);
  }, []);

  useEffect(() => {
    getDepartments();
    // getDetails();
    fetchPaymentStatus();
  }, []);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/apply/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  if (stage === "completed" || stage === "rejected" || stage === "approved") {
    return (
      <Navigate
        to={{
          pathname: `/apply`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div class="page-wrapper">
      {data && (
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-right">
                    <ol class="breadcrumb">
                      {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                      <li class="breadcrumb-item active">Education details</li>
                    </ol>
                  </div>
                  <h4 class="page-title">Education details</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="tab-content detail-list" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="general_detail">
                    <div class="row">
                      <div class="col-lg-12 col-xl-9 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <div class="">
                              <form
                                class="form-horizontal form-material mb-0"
                                onSubmit={updateBio}
                              >
                                <div class="form-group row">
                                  <div class="col-md-6">
                                    <label>First Choice</label>
                                    <select
                                      class="form-control"
                                      value={degreeChoice1}
                                      onChange={(e) =>
                                        setDegreeChoice1(e.target.value)
                                      }
                                    >
                                      <option>--Select--</option>
                                      {department?.map(({ id, department }) => (
                                        <option key={id} value={department}>
                                          {department}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <label>Second Choice</label>
                                    <select
                                      class="form-control"
                                      value={degreeChoice2}
                                      onChange={(e) =>
                                        setDegreeChoice2(e.target.value)
                                      }
                                    >
                                      <option>--Select--</option>
                                      {department?.map(({ id, department }) => (
                                        <option key={id} value={department}>
                                          {department}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                    <div class="form-group row">
                                      {/* <div class="col-md-4">
                                  <label>Post UTME score</label>
                                  <input
                                    type="number"
                                    placeholder="UTME score"
                                    class="form-control"
                                    value={postUtme}
                                    onChange={(e) =>
                                      setPostUtme(e.target.value)
                                    }
                                  />
                                </div> */}
                                      <div class="col-md-6">
                                        <label>Jamb number</label>
                                        <input
                                          type="text"
                                          placeholder="Jamb number"
                                          class="form-control"
                                          value={jambNo}
                                          onChange={(e) =>
                                            setJambNo(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div class="col-md-6">
                                        <label>UTME score</label>
                                        <input
                                          type="text"
                                          placeholder="UTME score"
                                          class="form-control"
                                          value={utmeScore}
                                          onChange={(e) =>
                                            setUtmeScore(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>

                                    {/* The ND info */}
                                    <p className="text-muted">
                                      For students applying with results (DE)
                                    </p>

                                    <div class="form-group row">
                                      <div class="col-md-4">
                                        <label>Institution Attended</label>
                                        <input
                                          type="text"
                                          placeholder="Name of Institution"
                                          class="form-control"
                                          value={prevSchool}
                                          onChange={(e) =>
                                            setPrevSchool(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <label>Course Studied</label>
                                        <input
                                          type="text"
                                          placeholder="Course of study"
                                          class="form-control"
                                          value={prevCourse}
                                          onChange={(e) =>
                                            setPrevCourse(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <label>Class of Degree</label>
                                        <select
                                          type="text"
                                          class="form-control"
                                          value={prevGrade}
                                          onChange={(e) =>
                                            setPrevGrade(e.target.value)
                                          }
                                        >
                                          <option> --Select-- </option>
                                          <option value="Distinction">
                                            Distinction
                                          </option>
                                          <option value="Upper Credit">
                                            Upper Credit
                                          </option>
                                          <option value="Lower Credit">
                                            Lower Credit
                                          </option>
                                          <option value="Pass">Pass</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div class="col-md-4">
                                        <label>CGPA</label>
                                        <input
                                          type="text"
                                          placeholder="CGPA"
                                          class="form-control"
                                          value={prevCGPA}
                                          onChange={(e) =>
                                            setPrevCGPA(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <label>Matric Number</label>
                                        <input
                                          type="text"
                                          placeholder="mat number"
                                          class="form-control"
                                          value={prevMatNum}
                                          onChange={(e) =>
                                            setPrevMatNum(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        <label>Date of Entry</label>
                                        <input
                                          type="date"
                                          placeholder=""
                                          class="form-control"
                                          value={prevStartDate}
                                          onChange={(e) =>
                                            setPrevStartDate(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <div class="col-md-4">
                                        <label>Date of Graduation</label>
                                        <input
                                          type="date"
                                          placeholder="UTME score"
                                          class="form-control"
                                          value={prevEndDate}
                                          onChange={(e) =>
                                            setPrevEndDate(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/*End of ND details */}

                                    <div class="form-group row">
                                      <div class="col-md-4">
                                        <label>
                                          <b>Olevel</b>(1st sitting)
                                        </label>
                                      </div>
                                    </div>
                                    <div class="form-group row">
                                      <div className="col-md-4">
                                        <label>Examination name</label>
                                        <select
                                          class="form-control"
                                          value={examName}
                                          onChange={(e) =>
                                            setExamName(e.target.value)
                                          }
                                        >
                                          <option>--Select--</option>
                                          <option value="WAEC">WAEC</option>
                                          <option value="WAEC GCE">
                                            WAEC GCE
                                          </option>
                                          <option value="NECO">NECO</option>
                                          <option value="NECO GCE">
                                            NECO GCE
                                          </option>
                                          <option value="NABTEC">NABTEC</option>
                                        </select>
                                      </div>
                                      <div class="col-md-4">
                                        <label>Examination reg number</label>
                                        <input
                                          class="form-control"
                                          value={examRegNum}
                                          onChange={(e) =>
                                            setExamRegNum(e.target.value)
                                          }
                                          placeholder="Enter examination reg number"
                                        ></input>
                                      </div>
                                      <div class="col-md-4">
                                        <label>Examination Date</label>
                                        <input
                                          class="form-control"
                                          value={examDate}
                                          onChange={(e) =>
                                            setExamDate(e.target.value)
                                          }
                                          type="date"
                                        ></input>
                                      </div>
                                    </div>

                                    <p className="text-muted">
                                      Select olevel grades (1st sitting)
                                    </p>

                                    <div class="form-group row">
                                      <div class="col-md-6">
                                        <select
                                          class="form-control"
                                          value={subject}
                                          onChange={(e) =>
                                            setSubject(e.target.value)
                                          }
                                        >
                                          <option>--Select subject--</option>
                                          {subjects?.map((subject) => (
                                            <option
                                              key={subject.index}
                                              value={subject.name}
                                            >
                                              {subject.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div class="col-md-3">
                                        <select
                                          class="form-control"
                                          value={grade}
                                          onChange={(e) =>
                                            setGrade(e.target.value)
                                          }
                                        >
                                          <option>--Select Grade--</option>
                                          {grading?.map((item) => (
                                            <option
                                              key={item.index}
                                              value={item.name}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div class="col-md-3">
                                        <button
                                          type="button"
                                          onClick={handleSubjectSelect}
                                          className="btn btn-danger"
                                        >
                                          Select Subject
                                        </button>
                                      </div>
                                    </div>
                                    {subjectList.length ? (
                                      <table>
                                        <thead>
                                          <th>Subject</th>
                                          <th>Grade</th>
                                          <th></th>
                                        </thead>
                                        {subjectList?.map((list) => (
                                          <tbody>
                                            <td>{list.subject}</td>
                                            <td>{list.grade}</td>
                                            <td
                                              onClick={() =>
                                                handleCourseDelete1(list.id)
                                              }
                                            >
                                              <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                                            </td>
                                          </tbody>
                                        ))}
                                      </table>
                                    ) : (
                                      ""
                                    )}
                                    <br />
                                    <div class="form-group row">
                                      <div class="col-md-4">
                                        <label>
                                          <b>2nd setting</b>(optional)
                                          <span
                                            onClick={() => setToggle(!toggle)}
                                          >
                                            {toggle ? (
                                              <ChevronDown />
                                            ) : (
                                              <ChevronUp />
                                            )}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    {toggle ? (
                                      <>
                                        <div class="form-group row">
                                          <div className="col-md-4">
                                            <label>Examination name</label>
                                            <select
                                              class="form-control"
                                              value={examName2}
                                              onChange={(e) =>
                                                setExamName2(e.target.value)
                                              }
                                            >
                                              <option>--Select--</option>
                                              <option value="waec">WAEC</option>
                                              <option value="neco">NECO</option>
                                            </select>
                                          </div>
                                          <div class="col-md-4">
                                            <label>
                                              Examination reg number
                                            </label>
                                            <input
                                              class="form-control"
                                              value={examRegNum2}
                                              onChange={(e) =>
                                                setExamRegNum2(e.target.value)
                                              }
                                              placeholder="Enter examination reg number"
                                            ></input>
                                          </div>
                                          <div class="col-md-4">
                                            <label>Examination Date</label>
                                            <input
                                              class="form-control"
                                              value={examDate2}
                                              onChange={(e) =>
                                                setExamDate2(e.target.value)
                                              }
                                              type="date"
                                            ></input>
                                          </div>
                                        </div>

                                        <p className="text-muted">
                                          Select olevel grades (2nd sitting)
                                        </p>

                                        <div class="form-group row">
                                          <div class="col-md-6">
                                            <select
                                              class="form-control"
                                              value={subject2}
                                              onChange={(e) =>
                                                setSubject2(e.target.value)
                                              }
                                            >
                                              <option>
                                                --Select subject--
                                              </option>
                                              {subjects?.map((subject) => (
                                                <option
                                                  key={subject.index}
                                                  value={subject.name}
                                                >
                                                  {subject.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <div class="col-md-3">
                                            <select
                                              class="form-control"
                                              value={grade2}
                                              onChange={(e) =>
                                                setGrade2(e.target.value)
                                              }
                                            >
                                              <option>--Select Grade--</option>
                                              {grading?.map((item) => (
                                                <option
                                                  key={item.index}
                                                  value={item.name}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <div class="col-md-3">
                                            <button
                                              type="button"
                                              onClick={handleSubjectSelect2}
                                              className="btn btn-danger"
                                            >
                                              Select Subject
                                            </button>
                                          </div>
                                        </div>
                                        {subjectList2.length ? (
                                          <table>
                                            <thead>
                                              <th>Subject</th>
                                              <th>Grade</th>
                                              <th></th>
                                            </thead>
                                            {subjectList2?.map((list) => (
                                              <tbody>
                                                <td>{list.subject}</td>
                                                <td>{list.grade}</td>
                                                <td
                                                  onClick={() =>
                                                    handleCourseDelete2(list.id)
                                                  }
                                                >
                                                  <Trash2 className="waves-effect align-self-center icon-dual-pink icon-lg" />
                                                </td>
                                              </tbody>
                                            ))}
                                          </table>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                )}

                                <div class="form-group">
                                  <button
                                    class="btn btn-gradient-primary btn-sm px-4 mt-3 float-right mb-0"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    Update Details
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal modal-rightbar fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="MetricaRightbar"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title mt-0" id="MetricaRightbar">
                    Appearance
                  </h5>
                  <button
                    type="button"
                    class="btn btn-sm btn-soft-primary btn-circle btn-square"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    <i class="mdi mdi-close"></i>
                  </button>
                </div>
                <div class="modal-body">
                  <ul
                    class="nav nav-pills nav-justified mt-2 mb-4"
                    role="tablist"
                  >
                    <li class="nav-item waves-effect waves-light">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#ActivityTab"
                        role="tab"
                      >
                        Activity
                      </a>
                    </li>
                    <li class="nav-item waves-effect waves-light">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#TasksTab"
                        role="tab"
                      >
                        Tasks
                      </a>
                    </li>
                    <li class="nav-item waves-effect waves-light">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#SettingsTab"
                        role="tab"
                      >
                        Settings
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content">
                    <div
                      class="tab-pane active "
                      id="ActivityTab"
                      role="tabpanel"
                    >
                      <div class="bg-light mx-n3">
                        <img
                          src="../assets/images/small/img-1.gif"
                          alt=""
                          class="d-block mx-auto my-4"
                          height="180"
                        />
                      </div>
                      <div class="slimscroll scroll-rightbar">
                        <div class="activity">
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  10 Min ago
                                </small>
                                <a href="#" class="m-0 w-75">
                                  Task finished
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>

                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-timer-off bg-soft-pink"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  50 Min ago
                                </small>
                                <a href="#" class="m-0 w-75">
                                  Task Overdue
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                              <span class="badge badge-soft-secondary">
                                Design
                              </span>
                              <span class="badge badge-soft-secondary">
                                HTML
                              </span>
                            </div>
                          </div>
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-alert-decagram bg-soft-purple"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  10 hours ago
                                </small>
                                <a href="#" class="m-0 w-75">
                                  New Task
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>

                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-clipboard-alert bg-soft-warning"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  yesterday
                                </small>
                                <a href="#" class="m-0 w-75">
                                  New Comment
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-clipboard-alert bg-soft-secondary"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  01 feb 2020
                                </small>
                                <a href="#" class="m-0 w-75">
                                  New Lead Meting
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>
                          <div class="activity-info">
                            <div class="icon-info-activity">
                              <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                            </div>
                            <div class="activity-info-text mb-2">
                              <div class="mb-1">
                                <small class="text-muted d-block mb-1">
                                  26 jan 2020
                                </small>
                                <a href="#" class="m-0 w-75">
                                  Task finished
                                </a>
                              </div>
                              <p class="text-muted mb-2 text-truncate">
                                There are many variations of passages.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="TasksTab" role="tabpanel">
                      <div class="m-0">
                        <div id="rightbar_chart" class="apex-charts"></div>
                      </div>
                      <div class="text-center mt-n2 mb-2">
                        <button type="button" class="btn btn-soft-primary">
                          Create Project
                        </button>
                        <button type="button" class="btn btn-soft-primary">
                          Create Task
                        </button>
                      </div>
                      <div class="slimscroll scroll-rightbar">
                        <div class="p-2">
                          <div class="media mb-3">
                            <img
                              src="../assets/images/widgets/project3.jpg"
                              alt=""
                              class="thumb-lg rounded-circle"
                            />
                            <div class="media-body align-self-center text-truncate ml-3">
                              <p class="text-success font-weight-semibold mb-0 font-14">
                                Project
                              </p>
                              <h4 class="mt-0 mb-0 font-weight-semibold text-dark font-18">
                                Payment App
                              </h4>
                            </div>
                          </div>
                          <span>
                            <b>Deadline:</b> 02 June 2020
                          </span>
                          <a href="javascript: void(0);" class="d-block mt-3">
                            <p class="text-muted mb-0">
                              Complete Tasks<span class="float-right">75%</span>
                            </p>
                            <div
                              class="progress mt-2"
                              style={{ height: "4px" }}
                            >
                              <div
                                class="progress-bar bg-secondary"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </a>
                        </div>
                        <hr class="hr-dashed" />
                      </div>
                    </div>
                    <div class="tab-pane" id="SettingsTab" role="tabpanel">
                      <div class="p-1 bg-light mx-n3">
                        <h6 class="px-3">Account Settings</h6>
                      </div>
                      <div class="p-2 text-left mt-3">
                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch1"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch1"
                          >
                            Auto updates
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch2"
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch2"
                          >
                            Location Permission
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch3"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch3"
                          >
                            Show offline Contacts
                          </label>
                        </div>
                      </div>
                      <div class="p-1 bg-light mx-n3">
                        <h6 class="px-3">General Settings</h6>
                      </div>
                      <div class="p-2 text-left mt-3">
                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch4"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch4"
                          >
                            Show me Online
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch5"
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch5"
                          >
                            Status visible to all
                          </label>
                        </div>

                        <div class="custom-control custom-switch switch-primary mb-3">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="settings-switch6"
                            checked=""
                          />
                          <label
                            class="custom-control-label"
                            for="settings-switch6"
                          >
                            Notifications Popup
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default EducationDetails;
