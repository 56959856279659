import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../components/services/auth/authService";
import avatar from "../../../assets/images/user.png"
import { GetApplicantDetails, GetApplicationFee, GetPaymentInvoice, GetPaymentStatus } from "../../../api/application";
import SmallCard from "../components/SmallCard";
import ApplicationCheckCard from "../components/ApplicationCheckCard";
import { Info } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import useDetails from "../../../hooks/useDetails";
import Footer from "../../../components/Footer";

function ApplicationDashboard() {
    const user = getCurrentUser()
    const [passport, setPassport] = useState()
    const [details, setDetails] = useState([])
    const [admissionFee, setAdmissionFee] = useState()
    const [resultFee, setResultFee] = useState()
    const [stage, setStage] = useState()
    const [submissionStatus, setSubmissionStatus] = useState('')

    const [applicationInvoice, setApplicationInvoice] = useState()
    const [resultInvoice, setResultInvoice] = useState()
    
  const location = useLocation();
  const navigate = useNavigate()

  const {data:dashboard, staging} = useDetails()

    const onImageError = (e) => {
        e.target.src = avatar
      }

      const getDetails = async()=> {
        try {
          const {data} = await GetApplicantDetails(user.application_id)
          console.log(data, "dataaaa")
          setPassport(data.photo)
          setStage(data.stage)
          setSubmissionStatus(data.isApplicationSubmitted)
          setDetails(data)
        } catch (error) {
          
        }
      }
      const fetchPaymentStatus = async () => {
        try {
          const { data } = await GetPaymentStatus(user.application_id);
          console.log(data, "statuss");
          console.log(data.application_fee, "statusssass");
          setAdmissionFee(data.application_fee)
          console.log(data.result_checking_fee, "checking fee");
          setResultFee(data.result_checking_fee)
        } catch (error) {}
      };

      const getInvoices = async () => {
        try {
            const {data} = await GetPaymentInvoice(user.application_id)
            setApplicationInvoice(data?.application_fee)
            setResultInvoice(data?.result_checking_fee)
            console.log("Invoice", data)
        } catch (error) {
            
        }
      }

      useEffect(() => {
        getDetails()
        fetchPaymentStatus()
        getInvoices()
      }, [])

      
  return (
    
    <div class="page-wrapper">
        {dashboard && 
    <div class="page-content-tab">

        <div class="container-fluid">
            
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="float-right">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                        <h4 class="page-title">Dashboard</h4>
                    </div>
                </div>
            </div>
            <div class="alert dspg-dark" role="alert">
          Welcome back, <strong>{user?.username}</strong>
        </div>
            <div className={`alert ${(stage === 'completed'|| stage === 'rejected' || stage === 'approved')? "dspg-success" : "dspg-danger"}`} role="alert">
          
          {stage === null ?
            "Please pay your fees"
            : stage === 'biodata'
                ? "Please update NOK biodata"
                : stage === 'nok'
                    ? "Please update education details"
                    : stage === 'educational'
                        ? "Preview your application and submit"
                        : (stage === 'completed' || stage === 'rejected' || stage === 'approved')
                            ? "Application submitted"
                            : stage === 'payment'
                                ? "Please update your biodata"
                                :""}
        </div>
        
        <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="met-profile">
                    <div class="row">
                      <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                        <div class="met-profile-main">
                          <div class="met-profile-main-pic">
                            <img
                              src={passport}
                              onError={onImageError}
                              alt="Passport"
                              className="rounded-circle"
                              style={{height: "100px", objectFit:'contain'}}
                            />
                          </div>
                          <div class="met-profile_user-detail">
                            <h3 class="">
                              {details
                                ? details?.surname + " " + details?.othername
                                : ""}
                            </h3>
                            <p class="mb-0">Applicant</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 ml-auto">
                        <ul class="list-unstyled">
                          <li class="">
                            <i class="dripicons-phone mr-2 text-info font-18"></i>{" "}
                            <b> phone </b> : {details ? details?.phone : ""}
                          </li>
                          <li class="mt-2">
                            <i class="dripicons-mail text-info font-18 mt-2 mr-2"></i>{" "}
                            <b> Email </b> : {details ? details?.email : ""}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="row">
        <div class="col-lg-12 align-self-center">
          <div className="card">
            <div className="card-body">
              <div class="">
                <h5 class="mt-0 mb-3 mt-1">
                  <b>Dear Candidate</b>
                </h5>
                <hr />
                <h6 class="mb-0">
                  College of Education, Warri is pleased to learn of your interest in our {details?.programmeType} programme. The information required sections of this online application form will assist us in processing your application.
                  <br/>
                  <br/>
                  <br/>
                  All the information contained in the application is confidential and will be used only by authorized officers. The form should be completed and submitted online.
                </h6>
                <br/>
                {staging === 'null' ? 
                <button className="btn btn-primary" onClick={() => navigate('/apply/payment')}>Proceed to Application</button>
                :''
                }
              </div>
            </div>
          </div>
        </div>
        </div>
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-6">
                    <SmallCard title="Application Fee" paymentStatus={admissionFee} invoice={applicationInvoice}/> 
                </div>
                <div class="col-md-6 col-lg-6">
                    <ApplicationCheckCard title="Admission Status" stage={staging}/>
                </div>                            
            </div>
        </div>
        <Footer/>
    </div>
        }
</div>
  );
}

export default ApplicationDashboard;
